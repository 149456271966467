<template>
  <main>
    <canvas id="peninas0a2A" class="canvas-h canvas-w"></canvas>
  </main>
</template>

<script>
import Chart from 'chart.js/auto';
import chartData from '../../../../../assets/datasheets/charts/pesoParaLaEdadNinas0a2A.json';

export default {
  props : ['x', 'y'],
  methods : {
    open(){

      const labels = chartData.datapoints;
      const datasets = [];

      chartData.series.forEach(s => {
        datasets.push({
          type: 'line',
          label: s.title,
          data: s.data,
          borderColor: s.color,
          fill: false,
          tension: 0.5,
          borderWidth : 1.5,
        });
      });

      datasets.push({
        type: 'scatter',
        backgroundColor: 'rgba(75, 00, 150,1)',
        borderColor: 'rgba(75, 00, 150,1)',
        label : 'Peso/Edad',
        data: [{
          "x" : Math.floor(this.x * 30),
          "y" : this.y
        }],
        options: {
          scales: {
            x: {
              type: 'linear',
              position: 'bottom'
            }
          },
        },
        elements : {
          point : {
            radius : 4
          }
        }
      });

      const data = {
        labels: labels,
        datasets: datasets
      };

      const config = {
        data: data,
        options: {
          responsive: true,
          plugins: {
            title: {
              display: true,
              text: 'Peso para la Edad Niñas de 0 a 2 Años'
            },
          },
          interaction: {
            intersect: false,
          },
          scales: {
            x: {
              display: true,
              title: {
                display: true,
                text: 'Edad En Meses'
              },
              ticks: {
                callback: function(value) {
                  let meses = new Array(24).fill(1).map((v,i) => i+1);
                  if(meses.includes(Number(value) / 30)){
                    return `${value / 30} M`;
                  }
                }
              },
            },
            y: {
              display: true,
              title: {
                display: true,
                text: 'Peso'
              },
              suggestedMin: 0,
            }
          },
          elements : {
            point : {
              radius : 0
            }
          }
        },
      };

      const ctx = document.getElementById('peninas0a2A').getContext('2d');

      new Chart(ctx, config);
    },
    export(){
      const canva = document.getElementById('peninas0a2A');
      const urlData = canva.toDataURL('image/png', 0.8);
      return {
        chart : 'Peso para la Edad Niñas 0 a 2 Años',
        imageData : urlData
      };
    }
  },
  created() {
    this.$nextTick(() => this.open());
  }
}

</script>

<style scoped>
  .canvas-h{
    height: 100%;
  }
  .canvas-w{
    width: 100%;
  }
</style>