<template>

  <main ref="resumen" id="resumen">
    <div class="py-2"><motivoConsultaShow :idHistoria="idHistoria"></motivoConsultaShow></div>
    <div class="py-2"><examenFisicoShow :idHistoria="idHistoria"></examenFisicoShow></div>
    <div class="py-2"><exploracionFisicaShow :idHistoria="idHistoria"></exploracionFisicaShow></div>
    <div class="py-2"><revision-sistemas-show :id-historia="idHistoria"></revision-sistemas-show></div>
    <div class="py-2">
      <div >
        <div class="card-header bg-light "><span><i class="fa fa-user-md"> </i> Graficas de Evaluación Nutricional</span> </div>
        <div class="card-body">
          <graficas-component :idHistoria="idHistoria" :usuario="usuario"></graficas-component>
        </div>
      </div>
    </div>
    <div class="py-2"><ordenClinicaShow :idHistoria="idHistoria"></ordenClinicaShow></div>
    <div class="py-2"><formulaMedicaShow :idHistoria="idHistoria"></formulaMedicaShow></div>
    <div class="py-2"><impresionDxShow :idHistoria="idHistoria"></impresionDxShow></div>
    <div class="py-2">
      <vacunas-show :id-usuario="idUsuario"></vacunas-show>
    </div>
    <div class="py-2"><aiepiShow :idHistoria="idHistoria"></aiepiShow></div>
    <div class="py-2"><valeShow :usuario="usuario" :idHistoria="idHistoria"></valeShow></div>
    <div class="py-2"><indexEvaluacionShow :idHistoria="idHistoria" :idUsuario="idUsuario"></indexEvaluacionShow></div>
    <div class="py-2"><intervenciones-show :id-historia="idHistoria"></intervenciones-show></div>
    <div class="py-2"><recomendaciones-show :id-historia="idHistoria"></recomendaciones-show></div>
  </main>

</template>
<script>
import motivoConsultaShow from "../../base/motivoConsulta/motivoConsultaShow";
import examenFisicoShow from "../../base/examenFisico/examenFisicoShow";
import ordenClinicaShow from "../../base/orden/ordenClinicaShow";
import formulaMedicaShow from "../../base/formulaMedica/formulaMedicaShow";
import impresionDxShow from "../../base/impresionDX/impresionDxShow";
import aiepiShow from "../../base/baseAiepi/index/aiepiShow";
import valeShow from "../../base/baseVale/valeShow";
import indexEvaluacionShow from "../../base/baseEscalaAbreviadaDesarrollo/index/indexEvaluacionShow";
import exploracionFisicaShow from "../../base/examenFisico/exploracionFisicaShow";
import graficasComponent from '../graficas';
import IntervencionesShow from "@/views/historias/base/intervenciones/intervencionesShow";
import RecomendacionesShow from "@/views/historias/base/recomendaciones/recomendacionesShow";
import RevisionSistemasShow from "@/views/historias/base/basePartesRevisionSistemas/revisionSistemasShow";
import VacunasShow from "@/views/historias/base/baseVacunas/index/vacunasShow";

export default {
  props: ['idHistoria','idUsuario', 'usuario'],
  components:{
    VacunasShow,
    RevisionSistemasShow,
    RecomendacionesShow,
    IntervencionesShow,
    graficasComponent,
    motivoConsultaShow,
    examenFisicoShow,
    ordenClinicaShow,
    formulaMedicaShow,
    impresionDxShow,
    aiepiShow,
    valeShow,
    indexEvaluacionShow,exploracionFisicaShow
  },
}
</script>
<style scoped>
.resumen-container {
  height:300px;
  overflow-y:scroll
}

</style>